import React, { useEffect, useState } from "react";

import { Link } from "gatsby";

import { useForm, Controller } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import PhoneInput from "react-phone-input-2";

import Select from "react-select";

import { Modal, Alert } from "react-bootstrap";

import Certificate from "../components/certificate/index";

import { dropdownStyle, getCounty } from "utils/dropdown";

import {
  donationData,
  indiaOptions,
  overseasOptions,
  plantCount,
} from "./donationDataCorporate";

import { ceil } from "lodash";

import config from "assets/config";
import moment from "moment";

// servcies
import DonationService from "services/donations/donation.service";

import CurrencyService from "services/currency/currency.service";

import ProjectViewthubnailslider from "../components/admin/project-thumbnail-slider";

import Projectlocationpops from "../components/admin/projectlocationpops";

import noimage from "assets/images/noImage.png";
import { toast, ToastContainer } from "react-toastify";

// import PropTypes from "prop-types";

export default function DonationGiftingForm({
  formType,
  validationSchema,
  amountSelect,
  baseAmountValue,
}) {
  const [countryList, setCountryList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [amountType, setAmountType] = useState("INR");

  const [proveType, setProveType] = useState(indiaOptions);

  const [currencyList, setCurrencyList] = useState();

  const [showPreview, setShowPreview] = useState(false);
  const [showAlertPreview, setShowAlertPreview] = useState(false);
  const [showAlertPopupPreview, setShowAlertPopupPreview] = useState(false);

  const [donationFormData, setdonationFormData] = useState();

  const [postalcodeLabel, setPostalcodeLabel] = useState("Pincode");

  const [citizenshipValue, setCitizenshipValue] = useState("India");

  const [convertedInrAmount, setConvertedInrAmount] = useState();

  const [customCardAmount, setCustomCardAmount] = useState();

  const [minumumAmount, setMinumumAmount] = useState(Number("399"));

  const [errorAmount, setErrorAmount] = useState();

  const [newCurrencyList, setNewCurrencyList] = useState();

  const [selectedProjectValue, setSelectedProjectValue] = useState();

  const [projectDescription, setProjectDescription] = useState([]);

  const [yecMessge, setYecMessage] = useState();

  const [isYec, setIsYec] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const changeCurrency = (ev, ev2) => {
    setMinumumAmount(Math.ceil(baseAmountValue / ev2));
    setAmountType(ev);
    // setAmountType(ev.target.value);
    setValue("amount", null, { shouldValidate: false, shouldDirty: false });
    setValue("customAmount", null, { shouldValidate: true, shouldDirty: true });
  };

  const convertAmount = (amount) => {
    if (currencyList) {
      const rate = currencyList[amountType];
      return amountType === "INR" ? amount : ceil(amount * rate);
    }
  };

  const toggleAlertPreview = () => {
    setShowAlertPreview(!showAlertPreview);
  };

  const proceedToPay = async (data) => {
    try {
      if (data) {
        const amountParse = JSON.parse(data.amount);

        let amount;

        if (typeof amountParse === "object") {
          amount = amountParse[amountType];
        } else {
          amount = data.amount;
        }

        const rate = currencyList[amountType];

        const conversionAmount =
          amountType === "INR" ? amount : ceil(amount * rate);

        donationData.userProfile.firstName = data.userName;
        donationData.userProfile.emailAddress = data.email;
        donationData.userProfile.phoneNumber = data.phone;
        donationData.userProfile.city = data.city ? data.city : "";
        donationData.userProfile.state = data.state ? data.state : "";
        donationData.userProfile.addressLine1 = data.address
          ? data.address
          : "";
        donationData.userProfile.postalCode = data.pincode ? data.pincode : "";
        donationData.userProfile.country = data.country.value;
        donationData.userProfile.citizenshipCountry = data.citizenship.value;

        if (data.identityType === "Pancard" && data.identityNumber !== "") {
          donationData.userProfile.taxId = data.identityNumber;
        }

        donationData.lineItems[0].currency = "INR";
        donationData.lineItems[0].amount = conversionAmount;

        let donorPhone = data.phone;
        donorPhone = donorPhone.replace("-", "");
        donorPhone = donorPhone.replace(" ", "");
        let recipientPhoneNumber = "";
        if (formType !== "donation") {
          recipientPhoneNumber = data.recipientPhone;
          recipientPhoneNumber = recipientPhoneNumber.replace("-", "");
          recipientPhoneNumber = recipientPhoneNumber.replace(" ", "");
        }

        donationData.lineItems[0].extras.donorName = data.userName;
        donationData.lineItems[0].extras.donorEmailAddress = data.email;
        donationData.lineItems[0].extras.donorPhoneNumber = donorPhone;
        donationData.lineItems[0].extras.donationType =
          formType === "donation" ? "donate" : "gift";
        donationData.lineItems[0].extras.giftReceiverName = data.recipientName;
        donationData.lineItems[0].extras.giftReceiverEmailAddress =
          data.recipientEmail;
        donationData.lineItems[0].extras.giftReceiverPhoneNumber =
          recipientPhoneNumber;
        donationData.lineItems[0].extras.giftMessage = data.occasionGift;
        donationData.lineItems[0].extras.projectId = projectList[0].value;
        donationData.lineItems[0].extras.donationReceivedFrom = "fbh_corporate";

        donationData.extras.identityNumber = data.identityNumber;
        donationData.extras.identityType = data.identityType;

        if (formType === "donation") {
          donationData.clientSuccessRedirectUrl =
            config.donationSuccessRedirect;
        } else {
          donationData.clientSuccessRedirectUrl = config.giftingSuccessRedirect;
        }

        donationData.clientFailureRedirectUrl = config.donationFailureRedirect;

        const ds = new DonationService();

        const responseData = await ds.donation(donationData);

        if (responseData && responseData.data) {
          if (responseData?.data?.error === "RES-0001") {
            setYecMessage(responseData?.data?.message);
            toggleAlertPreview();
          } else {
            const response = responseData.data;

            const form = document.createElement("form");
            document.body.appendChild(form);
            form.method = response.paymentGatewayRequestMethod;
            form.action = response.paymentGatewayUrl;

            JSON.parse(
              JSON.stringify(response.paymentGatewayRequestParamMap),
              function (key, value) {
                const inputelement = document.createElement("input");
                inputelement.setAttribute("type", "hidden");
                inputelement.setAttribute("name", key);
                inputelement.setAttribute("value", value);
                form.appendChild(inputelement);
              }
            );
            form.submit();
          }
        }
        // else if (responseData) {
        //   if ((responseData.error = "RES-0001")) {
        //     toggleAlertPreview();
        //   }
        // }
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  const onSubmit = (data) => {
    /*  if (formType === "donation") {
      window.location.href =
        "https://give.do/fundraisers/forestsbyheartfulness-creating-miniforests-of-indigenous-and-native-species-across-india-l5kkmln9";
    } */

    formType === "gifting"
      ? setdonationFormData(data)
      : setdonationFormData({});
    data && formType === "gifting" ? togglePreview() : proceedToPay(data);
  };

  const initCountryList = async () => {
    try {
      const cl = await getCounty();

      // const filterCl = cl.filter((item) => {
      //   return item.value === 'India';
      // });

      setCountryList(cl);
    } catch (err) {
      toast.error(err);
    }
  };

  const initProjectList = async () => {
    try {
      const cs = new DonationService();
      const cl = await cs.getProjectList();

      // const filterCl = cl.filter((item) => {
      //   return item.value === 'India';
      // });

      const projects = cl.data.projects.map((item) => {
        return { ...item, value: item.id, label: item.name };
      });

      /*setProjectList(
        projects?.filter((item) => {
          return (
            Number(item.donatedTrees) < Number(item.targetCount) &&
            item?.status === "ACTIVE"
          );
        })
      );*/
      setProjectList(projects);
    } catch (err) {
      toast.error(err);
    }
  };

  const getCurrencyList = async () => {
    try {
      const cs = new CurrencyService();

      const csResponse = await cs.getINR();

      csResponse && csResponse.data && csResponse.data.rates
        ? setCurrencyList(csResponse.data.rates)
        : setCurrencyList([]);
    } catch (err) {
      toast.error(err);
    }
  };

  const donationFromInit = async () => {
    if (amountSelect && amountSelect[1]) {
      setValue("amount", amountSelect[1].amount.INR, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("saplingCount", amountSelect[1].plantCount, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue(
        "citizenship",
        { value: "India", label: "India" },
        { shouldValidate: true, shouldDirty: true }
      );
      setValue(
        "country",
        { value: "India", label: "India" },
        { shouldValidate: true, shouldDirty: true }
      );
      setValue("currencyType", newCurrencyList?.[66], {
        shouldValidate: false,
        shouldDirty: false,
      });
      setValue("projectid", projectList?.[0]?.id, {
        shouldValidate: false,
        shouldDirty: false,
      });
    }

    const amountCheckbox = document.querySelectorAll(
      '.plant-box-wrapper input[type="checkbox"]'
    );

    if (amountCheckbox && amountCheckbox.length > 4) {
      amountCheckbox[1].checked = true;
      amountCheckbox[5].checked = true;
    }
  };

  useEffect(() => {
    (async () => {
      initCountryList();
      getCurrencyList();
    })();
  }, []);

  useEffect(() => {
    initProjectList();
    donationFromInit();

    var todate = moment("2022-04-02");
    var fromdate = moment("2022-03-30");
    var now = moment();

    if (now < todate && now > fromdate) {
      setShowAlertPopupPreview(true);
    }
  }, [amountSelect]);

  const updateProveType = (options, type) => {
    Promise.resolve()
      .then(() => setProveType(options))
      .then(() =>
        setValue("identityType", type, {
          shouldValidate: false,
          shouldDirty: false,
        })
      );
  };

  // const toggleAlertPopupPreview = () => {
  //   setShowAlertPopupPreview(!showAlertPopupPreview);
  // };

  const convertedAmount = (ev) => {
    const rate = currencyList[amountType];
    const conversionAmount =
      amountType === "INR" ? ev.target.value : ceil(ev.target.value * rate);
    setConvertedInrAmount(conversionAmount);
  };

  useEffect(() => {
    if (customCardAmount < minumumAmount) {
      setErrorAmount(minumumAmount);
    }
  }, [minumumAmount, customCardAmount]);

  useEffect(() => {
    if (currencyList) {
      let newData = [];
      Object.keys(currencyList).map((currencyType) => {
        return newData.push({
          label: currencyType,
          value: currencyList[currencyType],
        });
      });
      setNewCurrencyList(newData);
    }
  }, [currencyList]);

  useEffect(() => {
    if (projectDescription?.length === 0 || undefined || null) {
      setProjectDescription([projectList?.[0]]);
    } else {
      setProjectDescription(
        projectList.filter((item) => item?.id === selectedProjectValue)
      );
    }
  }, [selectedProjectValue, projectList]);

  useEffect(() => {
    const CheckYec = () => {
      const start_date = config.donationMainStartDate;
      const end_date = config.donationMainEndDate;

      const today = new Date(new Date().toLocaleDateString()).getTime();
      const restictSDate = new Date(start_date).getTime();
      const restictEDate = new Date(end_date).getTime();
      return restictSDate <= today && today <= restictEDate;
    };

    if (CheckYec() === true) {
      setIsYec(true);
    }
  }, []);

  return (
    <div className="dg-form-section">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row select-currency-row">
          <div className="col-md-8">
            <p>
              An initiative of Heartfulness Institute to nurture, protect and
              conserve native, endemic and endangered tree species of India
            </p>
          </div>
          <div className="col-md-4">
            {currencyList && (
              <Controller
                control={control}
                name="currencyType"
                defaultValue={null}
                render={(props) => {
                  return (
                    <Select
                      className={"cc-select"}
                      styles={dropdownStyle}
                      options={newCurrencyList}
                      placeholder="Select Currency"
                      value={props.field.value}
                      name={props.field.name}
                      onChange={(ev) => {
                        props.field.onChange(ev);
                        changeCurrency(ev.label, ev.value);
                      }}
                      inputRef={props.field.ref}
                    />
                  );
                }}
              />
            )}
          </div>
        </div>
        <div className="row plant-box-wrapper-row">
          {amountSelect.length > 0 ? (
            amountSelect.map((item) => {
              return (
                <div className="col-md-3 plant-box-wrapper" key={item.id}>
                  <input
                    type="checkbox"
                    name="amount"
                    value={item.amount[amountType]}
                    {...register("amount")}
                    onChange={(ev) => {
                      convertedAmount(ev);
                      setValue("amount", ev.target.value, {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                      setValue("customAmount", "", {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                      setValue("saplingCount", item.plantCount, {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                    }}
                  />
                  <div className="plant-box">
                    <div className="icon">
                      <img src={item.img} alt="sapling-img" />
                      {item.type === "radio" ? (
                        <span>{item.name}</span>
                      ) : getValues("customAmount") ? (
                        <span>
                          Plant{" "}
                          {plantCount(
                            convertAmount(getValues("customAmount")),
                            baseAmountValue
                          )}{" "}
                          Trees
                        </span>
                      ) : (
                        <span>{item.name}</span>
                      )}
                    </div>
                    <div className="amount">
                      {item.type === "radio" ? (
                        <p>
                          {amountType} {item.amount[amountType]}
                        </p>
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your amount"
                          name="customAmount"
                          disabled={item.amount[amountType] === 0}
                          {...register("customAmount")}
                          onKeyUp={(ev) => {
                            setCustomCardAmount(Number(ev.target.value));
                            convertedAmount(ev);

                            setValue(
                              "saplingCount",
                              plantCount(
                                convertAmount(ev.target.value),
                                baseAmountValue
                              ),
                              {
                                shouldValidate: true,
                                shouldDirty: true,
                              }
                            );
                            setValue("amount", ev.target.value, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                            ev.target.parentElement.parentElement.previousSibling.checked =
                              "true";
                          }}
                          onFocus={(ev) => {
                            // setCustomCardAmount(Number(ev.target.value))

                            if (ev.target) {
                              setValue(
                                "saplingCount",
                                plantCount(
                                  convertAmount(ev.target.value),
                                  baseAmountValue
                                ),
                                {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                }
                              );
                              setValue("amount", ev.target.value, {
                                shouldValidate: true,
                                shouldDirty: true,
                              });
                              ev.target.parentElement.parentElement.previousSibling.checked =
                                "true";
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div></div>
          )}
        </div>
        <div className="row">
          <div className="col-md-12">
            <input
              type="hidden"
              name="saplingCount"
              {...register("saplingCount")}
            />
            <p className="invalid-feedback">
              {errors.amount?.message}
              {errors.amount?.message ? "" : errors.saplingCount?.message}{" "}
              &nbsp;
              {/* {errors.saplingCount?.message && amountType == 'INR' && !errors.amount?.message ? `(₹ ${baseAmountValue})` : ''} */}
              {/* {errors.saplingCount?.message && amountType == 'USD' && !errors.amount?.message ? `($ 3)` : ''} */}
              {errors.saplingCount?.message && !errors.amount?.message
                ? `(${amountType} ${errorAmount})`
                : ""}
            </p>
            <p className="invalid-feedback">{errors.customAmount?.message}</p>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-md-12">
            <div className="fc-wrapper">
              <label htmlFor="projectid" className="form-label">
                Project Name
              </label>
              <select
                name="projectid"
                id=""
                className="form-control"
                {...register("projectid")}
                onChange={(e) => {
                  setSelectedProjectValue(e.target.value);
                }}
              >
                {projectList?.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
              </select>

              {projectDescription?.map((item) => {
                return (
                  <div className="row description-wrapper">
                    <div className="col-md-9">
                      <p>
                        <b>Description</b> :{" "}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                        />
                      </p>
                      <div className="description-loation-flex">
                        <div>
                          <p>
                            <b>View location</b> :
                          </p>
                        </div>
                        <div className="location-icon-wrapper">
                          <Projectlocationpops data={item} />
                        </div>
                      </div> 
                    </div>
                    <div className="col-md-3">
                      <div
                        className="wid-40 padding-10"
                        style={{ width: "170px" }}
                      >
                        {item?.projectImageUrls && item?.projectImageUrls ? (
                          <ProjectViewthubnailslider
                            images={item?.projectImageUrls}
                          />
                        ) : (
                          <img src={noimage} alt="" />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}

              <p className="invalid-feedback">{errors.projectid?.message}</p>
            </div>
          </div>
            */}
          <div className="col-md-12">
            <div className="fc-wrapper">
              <label htmlFor="userName" className="form-label">
                Donor Name
              </label>
              <input
                type="text"
                {...register("userName")}
                className={`form-control ${
                  errors.userName?.message ? "invalid" : ""
                }`}
                id="userName"
                placeholder="Enter your Full Name"
              />
              <p className="invalid-feedback">{errors.userName?.message}</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="fc-wrapper">
              <label htmlFor="email" className="form-label">
                Donor Email ID
              </label>
              <input
                type="email"
                {...register("email")}
                className={`form-control ${
                  errors.email?.message ? "invalid" : ""
                }`}
                id="email"
                placeholder="Enter your Email address"
              />
              <p className="invalid-feedback">{errors.email?.message}</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="fc-wrapper">
              <label htmlFor="phone" className="form-label">
                Phone
              </label>
              <div
                className={`telInput ${errors.phone?.message ? "invalid" : ""}`}
              >
                <Controller
                  control={control}
                  name="phone"
                  defaultValue={null}
                  render={(props) => {
                    return (
                      <PhoneInput
                        country={"in"}
                        value={props.field.value}
                        name={props.field.name}
                        onChange={(value, country, e, formattedValue) => {
                          props.field.onChange(formattedValue);
                        }}
                        inputRef={props.field.ref}
                      />
                    );
                  }}
                />
              </div>
              <p className="invalid-feedback">{errors.phone?.message}</p>
            </div>
          </div>

          {formType === "donation" ? (
            <div></div>
          ) : (
            <>
              <div className="col-md-6">
                <div className="fc-wrapper">
                  <label htmlFor="recipientName" className="form-label">
                    Recipient Name
                  </label>
                  <input
                    type="text"
                    {...register("recipientName")}
                    className={`form-control ${
                      errors.recipientName?.message ? "invalid" : ""
                    }`}
                    id="recipientName"
                    placeholder="Enter Name of your Recipient"
                  />
                  <p className="invalid-feedback">
                    {errors.recipientName?.message}
                  </p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="fc-wrapper">
                  <label htmlFor="recipientEmail" className="form-label">
                    Recipient Email ID
                  </label>
                  <input
                    type="email"
                    {...register("recipientEmail")}
                    className={`form-control ${
                      errors.recipientEmail?.message ? "invalid" : ""
                    }`}
                    id="recipientEmail"
                    placeholder="Enter Email ID of your Recipient"
                  />
                  <p className="invalid-feedback">
                    {errors.recipientEmail?.message}
                  </p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="fc-wrapper">
                  <label htmlFor="phone" className="form-label">
                    Receipient Phone
                  </label>
                  <div
                    className={`telInput ${
                      errors.recipientPhone?.message ? "invalid" : ""
                    }`}
                  >
                    <Controller
                      control={control}
                      name="recipientPhone"
                      defaultValue={null}
                      render={(props) => {
                        return (
                          <PhoneInput
                            country={"in"}
                            value={props.field.value}
                            name={props.field.name}
                            onChange={(value, country, e, formattedValue) => {
                              props.field.onChange(formattedValue);
                            }}
                            inputRef={props.field.ref}
                          />
                        );
                      }}
                    />
                  </div>
                  <p className="invalid-feedback">{errors.phone?.message}</p>
                </div>
              </div>
            </>
          )}

          {formType === "donation" ? (
            <div></div>
          ) : (
            <div className="col-md-6">
              <div className="fc-wrapper">
                <label htmlFor="occasionGift" className="form-label">
                  Occasion of Gift
                </label>
                <input
                  type="text"
                  {...register("occasionGift")}
                  className={`form-control ${
                    errors.occasionGift?.message ? "invalid" : ""
                  }`}
                  id="occasionGift"
                  placeholder="Type your message here"
                />
                <p className="invalid-feedback">
                  {errors.occasionGift?.message}
                </p>
              </div>
            </div>
          )}

          <div className="col-md-6">
            <div className="fc-wrapper">
              <label htmlFor="city" className="form-label">
                City
              </label>
              <input
                type="text"
                {...register("city")}
                className={`form-control ${
                  errors.city?.message ? "invalid" : ""
                }`}
                id="city"
              />
              <p className="invalid-feedback">{errors.city?.message}</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="fc-wrapper">
              <label htmlFor="state" className="form-label">
                State
              </label>
              <input
                type="text"
                {...register("state")}
                className={`form-control ${
                  errors.state?.message ? "invalid" : ""
                }`}
                id="state"
              />
              <p className="invalid-feedback">{errors.state?.message}</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="fc-wrapper">
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <input
                type="text"
                {...register("address")}
                className={`form-control ${
                  errors.address?.message ? "invalid" : ""
                }`}
                id="address"
                placeholder="Enter your street address"
              />
              <p className="invalid-feedback">{errors.address?.message}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="fc-wrapper">
              <label htmlFor="pincode" className="form-label">
                {postalcodeLabel}
              </label>
              <input
                type="text"
                {...register("pincode")}
                className={`form-control ${
                  errors.pincode?.message ? "invalid" : ""
                }`}
                id="pincode"
              />
              <p className="invalid-feedback">{errors.pincode?.message}</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="fc-wrapper">
              <label htmlFor="citizenship" className="form-label">
                Country
              </label>
              <Controller
                control={control}
                name="country"
                defaultValue={null}
                render={(props) => {
                  return (
                    <Select
                      className={`cc-select ${
                        errors.country?.message ? "invalid" : ""
                      }`}
                      styles={dropdownStyle}
                      options={countryList}
                      placeholder="Select your Country"
                      value={props.field.value}
                      name={props.field.name}
                      onChange={(ev) => {
                        props.field.onChange(ev);
                        setPostalcodeLabel(
                          ev.value === "India" ? "Pincode" : "Zipcode"
                        );
                      }}
                      inputRef={props.field.ref}
                    />
                  );
                }}
              />
              <p className="invalid-feedback">{errors.country?.message}</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="fc-wrapper">
              <label htmlFor="citizenship" className="form-label">
                Citizenship
              </label>
              <Controller
                control={control}
                name="citizenship"
                defaultValue={null}
                render={(props) => {
                  return (
                    <Select
                      className={`cc-select ${
                        errors.citizenship?.message ? "invalid" : ""
                      }`}
                      styles={dropdownStyle}
                      options={countryList}
                      placeholder="Select your Country"
                      value={props.field.value}
                      name={props.field.name}
                      onChange={(ev) => {
                        props.field.onChange(ev);
                        setCitizenshipValue(ev.value);
                        if (ev.value === "India") {
                          updateProveType(indiaOptions, "Pancard");
                        } else {
                          updateProveType(overseasOptions, "Passport");
                        }
                      }}
                      inputRef={props.field.ref}
                    />
                  );
                }}
              />
              <p className="invalid-feedback">{errors.citizenship?.message}</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="fc-wrapper">
              <label htmlFor="IDType" className="form-label">
                ID Type
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  {...register("identityNumber")}
                  className={`form-control ${
                    errors.identityNumber?.message ? "invalid" : ""
                  }`}
                  id="identityNumber"
                />
                <div>
                  <select
                    name="identityType"
                    id=""
                    className="form-control"
                    {...register("identityType")}
                  >
                    {proveType.map((item) => {
                      return (
                        <option key={item.id} value={item.name}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <p className="invalid-feedback">
                  {errors.identityNumber?.message}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <p className="card-type-info">
              {citizenshipValue === "India" ? (
                <div>
                  PAN Number is mandatory for income tax benefits under 80G. If
                  you do not have PAN number, please enter any other ID. If no
                  ID is entered, the organization needs to pay 30% tax on the
                  donation received.
                </div>
              ) : (
                <div>
                  If you file your income in India, please enter India PAN
                  Number for tax benefits. Otherwise, please enter the Passport
                  number. If neither entered, the organization needs to pay 30%
                  tax on the donation received.
                </div>
              )}
            </p>
            <p>
              {citizenshipValue !== "India" ? (
                <Alert variant="secondary">
                  On account of change in Government regulations, online
                  donations through website is not possible. You can contact{" "}
                  <a
                    href="mailto:donations@heartfulnessapp.org"
                    style={{ textDecoration: "none" }}
                  >
                    donations@heartfulnessapp.org
                  </a>{" "}
                  for assistance.
                </Alert>
              ) : (
                <div></div>
              )}
            </p>

            <p>
              {convertAmount(getValues("amount")) > 300000 &&
              citizenshipValue === "India" ? (
                <Alert variant="secondary">
                  Dear Donor, the Instamojo team is currently restricting
                  donation amounts above Rs 3 lakhs on account of their security
                  reasons. This has been taken up with them to remove the limit
                  and the same would be resolved shortly. Hence for the time
                  being, please try to make multiple donations of Rs 3 lakhs and
                  below to donate any amount above Rs 3 Lakhs. We deeply regret
                  the inconvenience caused in this regard.
                </Alert>
              ) : (
                <div></div>
              )}
            </p>

            <div className="submit-btn-wrapper text-center">
              <button
                // type="submit"
                disabled={
                  (convertAmount(getValues("amount")) > 300000 &&
                    citizenshipValue === "India") ||
                  citizenshipValue !== "India"
                }
                className="proceed-to-pay btn btn-secondary"
              >
                {/* Proceed to pay  {amountType === 'INR' ? '₹' : '$'} {getValues('amount')} */}
                Proceed to pay {amountType} {getValues("amount")}{" "}
                {getValues("amount") &&
                convertedInrAmount !== undefined &&
                amountType !== "INR"
                  ? `(₹ ${convertedInrAmount})`
                  : ""}
              </button>
            </div>
            <p className="terms-conditions">
              By continuing you agree to our
              <Link to="https://heartfulness.org/en/terms/" target="_blank">
                terms of service
              </Link>
              and
              <Link
                to="https://heartfulness.org/en/privacy-policy/"
                target="_blank"
              >
                privacy policy
              </Link>
            </p>
          </div>
        </div>
      </form>
      {isYec && (
        <div className="overlayBackground">
          <div className="overlayContent">
            Donations via online to HFI India are closed from 30th March 2023 to
            02nd April 2023 for complying with Year end accounting procedures.
            If you want to donate during this period, please send an email to{" "}
            <a
              href="mailto:accounts@heartfulnessinstitute.org"
              style={{ textDecoration: "none" }}
            >
              accounts@heartfulnessinstitute.org
            </a>{" "}
            for details.
          </div>
        </div>
      )}
      <Modal
        show={showPreview}
        onHide={togglePreview}
        dialogClassName="certificate-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Heartfulness Tree Gift Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Certificate details={donationFormData}></Certificate>
        </Modal.Body>
        <Modal.Footer>
          <button
            // type="button"
            className="btn btn-grey"
            onClick={togglePreview}
          >
            Close
          </button>
          <button
            // type="submit"
            className="btn btn-primary"
            onClick={() => {
              proceedToPay(donationFormData);
            }}
          >
            {/* Proceed to pay {amountType === 'INR' ? '₹' : '$'} {getValues('amount')} */}
            Proceed to pay {amountType} {getValues("amount")}{" "}
            {amountType !== "INR" ? `(₹ ${convertedInrAmount})` : ""}
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showAlertPreview}
        onHide={toggleAlertPreview}
        dialogClassName="certificate-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Donation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Donations via online to HFI India are closed from 30th March 2023 to
          02nd April 2023 for complying with Year end accounting procedures. If
          you want to donate during this period, please send an email to{" "}
          <a
            href="mailto:accounts@heartfulnessinstitute.org"
            style={{ textDecoration: "none" }}
          >
            accounts@heartfulnessinstitute.org
          </a>{" "}
          for details.
        </Modal.Body>
        <Modal.Footer>
          <button
            // type="button"
            className="btn btn-grey"
            onClick={toggleAlertPreview}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showAlertPopupPreview}
        backdrop="static"
        keyboard={false}
        dialogClassName="certificate-modal"
      >
        <Modal.Header>
          <Modal.Title>Donations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Donations via online to HFI India are closed from 30th March 2022 to
          02nd April 2022 for complying with Year ending accounting procedures.
          <br></br>
          You can email{" "}
          <a href="mailto:accounts@heartfulnessinstitute.org">
            accounts@heartfulnessinstitute.org
          </a>{" "}
          and get the bank details for making NEFT/RTGS.
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </div>
  );
}

// DonationGiftingForm.propTypes = {
//   formType: PropTypes.string.isRequired,
//   props: PropTypes.objectOf(PropTypes.object()).isRequired,
//   field: PropTypes.objectOf(PropTypes.object()).isRequired,
// };
